<script setup>
</script>

<template>
    <header class="u-position-sticky">

    </header>
</template>

<style scoped lang="scss">
header {
    --p-header-bg-color: var(--color-neutral-0);
    --p-header-border-color: var(--color-border);
    background-color: hsl(var(--p-header-bg-color));
    border-block-end: solid 0.0625rem hsl(var(--p-header-border-color));
}
</style>
