import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '@/views/DashboardView.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: DashboardView,
        meta: {
            title: 'Dashboard',
            placement: 'main',
            icon: 'icon-home',
        },
    },
    {
        path: '/subscriptions',
        name: 'subscriptions',
        component: () => import('../views/SubscriptionsView.vue'),
        meta: {
            title: 'Abonnementer',
            placement: 'main',
            icon: 'icon-refresh',
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue'),
        meta: {
            title: 'Medarbejdere',
            placement: 'main',
            icon: 'icon-user-group',
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
