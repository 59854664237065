<script setup>
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const router = useRouter();
const menuItems = computed(() => router.getRoutes()
    .filter((route) => route.meta.placement === 'main')
    .map((route) => ({ ...route, selected: route.name === router.currentRoute.value.name })));

</script>

<template>
    <nav class="u-position-sticky">
        <div class="side-nav">
            <div class="side-nav-main">
                <section class="drop-section">
                    <ul class="drop-list">
                        <li class="drop-list-item" v-for="menuItem in menuItems" :key="menuItem.name">
                            <router-link :to="{ name: menuItem.name }" :class="{ 'drop-button': true, 'is-selected': menuItem.selected }">
                                <span :class="menuItem.meta.icon" aria-hidden="true"></span>
                                <span class="text">{{ menuItem.meta.title }}</span>
                            </router-link>
                        </li>
                        <!-- <li class="drop-list-item">
                            <a class="drop-button" href="">
                                <span class="icon-user-group" aria-hidden="true"></span>
                                <span class="text">Medarbejdere</span>
                            </a>
                        </li>
                        <li class="drop-list-item">
                            <router-link :to="{ name: 'subscriptions' }" class="drop-button">
                                <span class="icon-refresh" aria-hidden="true"></span>
                                <span class="text">Abonnementer</span>
                            </router-link>
                        </li> -->
                    </ul>
                </section>
            </div>
            <div class="side-nav-bottom">
                <section class="drop-section">
                    <a class="drop-button" href="">
                        <span class="icon-cog" aria-hidden="true"></span>
                        <span class="text">Indstillinger</span>
                    </a>
                </section>
            </div>
        </div>
        <nav v-if="false">
            <router-link to="/">Home</router-link>
            |
            <router-link to="/about">About</router-link>
        </nav>
    </nav>
</template>

<style scoped lang="scss">

</style>
